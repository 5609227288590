<template>
    <div class="flex flex-1 m-4 justify-center bg-catskill-white-100 rounded-xl shadow-sm bg-catskill-white-100">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout',
}
</script>
